@import '../scss/utils_theme.scss';
@import '../scss/utils_flex.scss';

body {
  font-family: "Open Sans", sans-serif;
}

body, .wrapper {
  overflow-x: hidden;
  position: relative;
}

.app-spinner {
  position: fixed;
}

.centered-position {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.no-scroll {
  overflow: hidden;
}

.btn {
  -webkit-appearance: none !important;
}

.btn.btn-primary{
  background: #039be5;
}

a, .btn.btn-link{
  color:  #039be5;
  text-decoration: none;

  &:hover{
    color:  #039be5;
  }
}

// Columns

.col {
  @media (max-width: 400px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}


// Sidebar
.sidebar {
  background: $artomos-theme-color-sidebar;

  .nav-link {
    &:hover {
      background: rgba(0, 0, 0, .15)
    }

    &.active {
      background: rgba(255,255,255,.1);
    }
    &.active .nav-icon{
      color: white
    }
  }

  .nav-dropdown-toggle::before{
    transform: rotateZ(90deg)
  }

}

// Size for h1 page titles
.page-title {
  margin-bottom: $artomos-theme-unit * 4;
  font-size: 26px;
}

// General utils
.util-word-break{
  word-break: break-all;
}

// Animations
.animate_flash {
  animation: animate_flash .55s ease-in-out ;
}

@keyframes animate_flash {
  50% {
    opacity: 0;
  }
}
